:root {
  --corners: 0.25rem;
  --line: #eee 1px solid;
  --spacing-unit: 1rem;
}

.tabContainer {
  width: 95%;
  margin: 0 auto;
  padding-left: .3rem;
  padding-right: .3rem;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.tabList {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.tab {
  padding: var(--spacing-unit);
  margin: 1em 0;
  color: rgb(192, 59, 59);
  font-weight: 700;
  width: 11.5%;
  font-size: 23px;
  background-color: transparent;
  border: none;
  /* transform: skewY(8deg); */
}


.tab--selected {
  color: black;
  border: none;
}

.tab__panel {
  padding: 2rem var(--spacing-unit);
}

.grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

@media(max-width: 800px){
  .tabList {
    flex-wrap: wrap;
  }

  .tab {
    width: 30%;
    font-size: 1.1em;
  }
}