.admin_container {
  width: 100%;
  height: 100%;
}

.admin_form {
  border: 5px solid black;
  width: 25%;
  min-width: 300px;
  height: auto;
  min-height: 200px;
  top: 50%;
  left: 50%;
}