.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modalMain {
  position:fixed;
  background: white;
  width: 35%;
  min-width: 400px;
  height: auto;
  padding-bottom: 2em;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  color: black;
}

.modalMain h2 {
  font-size: 26px;
  line-height: .6em;
  letter-spacing: .075em;
}

.modalMain button {
  font-size: 18px;
  background: black;
  color: white;
  border: 1px solid black;
  height: 1.8em;
}

.modalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalInput {
  display: flex;
  width: 100%;
  margin: .2em;
  height: 1.5em;
  align-items: center;
}

.modalInput p {
  font-size: 21px;
  margin-right: 1em;
  width: 30%;
  text-align: right;
}

.modalInput input {
  width: 50%;
}

.textarea {
  align-items: flex-start;
  margin-bottom: 1.5em;
}

.modalBtn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: .5em;
}

.modalBuy {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.modalBuy a {
  margin: 1.5em;
  font-size: 23px;
  letter-spacing: .12em;
  line-height: 1em;
}

.modalStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalStyle img {
  height: 55px;
  margin-right: 1rem;
}

@media(max-width: 800px){ 
  .modalMain {
    min-width: 350px;
  }
}