.style_container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 0;
}

.style_container h2 {
  font-size: 1.9rem;
  line-height: 1rem;
  letter-spacing: .05rem;
}

.style_container h3 {
  font-size: 1.4rem;
  line-height: 1.3rem;
}

.style_container p {
  font-size: 1.05rem;
  letter-spacing: .025rem;
  line-height: 1.2rem;
}

.style_cardRows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media(max-width: 800px){
  .style_container h2 {
    font-size: 1.9rem;
    line-height: 1.9rem;
    letter-spacing: .05rem;
  }
  
  .style_container h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  
  .style_container p {
    font-size: 1.05rem;
    letter-spacing: .025rem;
    line-height: 1.2rem;
  }
}