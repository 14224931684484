.directions_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
}

.bold {
  color: #C9283D;
}

.divider {
  background-color: #C9283D;
  height: 2px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.directions_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  justify-content: space-around;
}

.directions_content h2 {
  font-size: 30px;
  line-height: 1em;
  margin-bottom: 0;
}

.directions_content h3 {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: 0;
}

.directions_content p {
  font-size: 20px;
  line-height: 1.8em;
  margin-bottom: 1rem;
}

.directions_content .directions_list {
  text-align: left;
}

.directions_info {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: 2rem 0;
}

.directions_info img {
  width: 325px;
  height: auto;
}


@media(max-width: 800px){
  .directions_container{
    width: 90%;
  }

  .directions_info {
    flex-direction: column;
    align-items: center;
  }
}