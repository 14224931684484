.landing_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_bg {
  background-image: url('../assets/NXGNStudioBG.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 85%;
  min-height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.landing_info {
  background-color: rgb(0,0,0,0.1);
  color: white;
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing_info h2 {
  font-size: 34px;
  margin: .67em 0;
  letter-spacing: -.02em;
  line-height: 1em;
  font-weight: 700;
}

.landing_info h3 {
  font-size: 15px;
  line-height: 1em;
  letter-spacing: .12em;
  margin: .8em 0;
}

.landing_info h1 {
  font-size: 12px;
  line-height: 1.68em;
  margin-top: 1em;
}

.landing_bg a {
  color: #131656;
  background-color: white; 
  line-height: 1.5rem;
  margin-top: 20rem;
  margin-left: 4rem;
  text-decoration: none;
  font-weight: bold;
}

@media(max-width: 800px){
  .landing_bg {
    margin-top: 50px;
  }

  .landing_bg {
    min-height: 13rem;
    height: auto;
  }

  .landing_bg a {
    font-size: .3rem;
    margin-top: 8.5rem;
    margin-left: 1rem;
  }
}