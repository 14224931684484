.about_container {
  margin: 2rem auto;
  text-align: center;
  width: 90%;
}

.about_container h2 {
  font-size: 30px;
  line-height: 1em;
  margin-bottom: 0;
}

.about_container h3 {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: 1rem;
}

.about_container p {
  font-size: 20px;
  line-height: 1.8em;
  margin-bottom: 1rem;
}

.about_top {
  margin-bottom: 1rem;
}

.about_top p {
  margin: 1rem auto;
  width: 80%;
  line-height: 1.4em;
}

.about_bottom {
  text-align: left;
  width: 80%;
  margin: 2rem auto;
}

.about_info a {
  text-decoration: none;
}

@media(max-width: 800px){
  .about_container {
    margin-top: 250px;
  }

  .about_top img {
    width: 100%;
  }

  .about_top p {
    width: 100%;
  }

}