.contact_container {
  width: 90%;
  text-align: left;
  margin: 0 auto;
}

.bold {
  color: #C9283D;
}

.subtext {
  font-size: 1rem;
  color: #C9283D;
}

.divider {
  background-color: #C9283D;
  height: 2px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.contact_divider {
  background-color: #858585;
  height: 2px;
  width: 60%;
  margin-bottom: 1rem;
}

.contact_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  width: 80%;
}

.contact_content h2 {
  font-size: 30px;
  line-height: 1em;
  margin-bottom: 0;
}

.contact_content h3 {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: 0;
}

.contact_content h4 {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: 0;
}

.contact_content p {
  font-size: 20px;
  line-height: 1.8em;
}

.contact_phone .contact_note {
  font-size: 16px;
}

.contact_phone {
  margin: 2rem 0;
  width: 100%;
}

.contact_email {
  margin-bottom: 2rem;
  width: 100%;
}

.contact_send {
  width: 100%;
  margin-bottom: 2rem;
}

.contact_form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.contact_input_split {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}

.contact_input_title {
  background-color: #e6e6e6;
  border-right: 1px solid black;
  white-space: nowrap;
  margin: 0;
  width: 45%;
  padding: 5% 0;
}

.contact_input_field {
  border: 2px solid lightslategrey;
  border-radius: 3px;
  line-height: 2rem;
  width: 90%;
  height: 1.5rem;
  margin: auto 20px;
}

.contact_input_split textarea {
  height: 20rem;
  margin: .5rem 20px;
}

.contact_submit {
  margin-top: .8rem;
  width: 30%;
  height: 3rem;
  font-size: 16px;
  font-weight: bold;
  color: aliceblue;
  background-color: #C9283D;
  border: none;
}

@media(max-width: 800px){
  .contact_container{
    width: 100%;
    text-align: center;
  }

  .contact_content {
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }

  .contact_divider {
    margin: 0 auto 1rem;
    width: 90%;
  }

  .contact_form {
    width: 100%;
  }
  
  .contact_input_split h4 {
    font-size: .9rem;
    white-space: pre-wrap;
  }
  
  .contact_submit { 
    width: 90%;
    margin: .5rem auto;
  }
}