.ins_container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  white-space: pre-wrap;
}

.ins_topSect {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: red solid 3px;
  width: 90%;
  padding: 2em 0;
  margin-bottom: 2em;
}

.ins_right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ins_right img {
  width: 100%;
  height: auto;
}

.ins_right p {
  font-size: 24px;
  margin-top: 1em;
  letter-spacing: .13em;
}

.ins_details {
  width: 50%;
}

.ins_details p, h2, h3 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ins_details p {
  font-size: 20px;
  line-height: 1.8em;
}

.ins_details h2 {
  font-size: 30px;
  line-height: 1em;
}

.ins_details h3 {
  font-size: 23px;
  line-height: 1em;
}

.ins_schedule {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2em;
}

@media(max-width: 800px) {
 .ins_topSect {
   flex-direction: column-reverse;
 } 
 
 .ins_details {
   width: 100%;
   text-align: center;
  }
  
 .ins_right {
   width: 100%;
 }
}