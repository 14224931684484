.fee_content {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding-top: 20px;
}

.fee_content h2 {
  font-size: 1.9rem;
  line-height: 1.68rem;
  margin-top: 1rem;
}

.fee_content h3 {
  text-align: left;
  font-size: 1.6rem;
  margin: .67rem 0;
  margin-left: 5.5%;
  letter-spacing: -.02rem;
  line-height: 1rem;
  font-weight: 700;
}

.fee_content h4 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1rem;
  margin-bottom: .5em;
}

.fee_content p {
  font-size: 1.05rem;
  letter-spacing: .025rem;
  line-height: 1.2rem;
}

.fee_japanese {
  color: white;
  font-size: .9rem;
}

.fee_japaneseHeader {
  font-size: 2.1rem;
  line-height: 4rem;
  font-weight: bold;
}

.fee_prices {
  font-size: 1rem;
}

.fee_table {
  margin: 0 auto;
}

.fee_row {
  background-color: #C9283D;
  border-radius: 3px;
  border: 3.5px solid black;
  width: 250px;
  padding: 1rem;
  vertical-align: middle;
}

.fee_rowAlt {
  background-color: #eeeeee;
  border-radius: 3px;
  border: 3.5px solid black;
  width: 250px;
  padding: 1rem;
  vertical-align: middle;
}

.fee_btn {
  border: 2px solid white;
  background-color: black;
  color: white;
  padding: .25rem 2rem;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

@media(max-width: 800px){
  .fee_content {
    width: 100%;
    overflow-block: hidden;
  }
  .fee_content p {
    width: 90%;
    margin-left: 1.4rem;
  }
  .fee_content h2 {
    text-align: center;
  }
  .fee_content img {
    margin: 0 auto;
    width: 350px;
    height: 350px;
  }
  .fee_btn {
    padding: 5px;
  }
  .fee_row {
    height: 5rem;
    width: 100%;
    padding: .1rem 0;
  }
  .fee_rowAlt {
    height: 5rem;
    width: 100%;
    padding: .1rem 0;
  }
  .fee_table {
    width: 350px;
  }
}