.staff_container {
  margin: 0 auto;
  padding-top: 10px;
}

.staff_container p, h3, h4 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.staff_topSect {
  text-align: center;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
}

.staff_title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1em;
}

.staff_pageDesc {
  font-size: 20px;
  line-height: 1.8em;
}

.staff_spinner {
  display: flex;
  justify-content: center;
}

.staff_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}