.class_cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
  height: auto;
  background-color: black;
  margin: 1em 0;
  color: white;
}

.class_image {
  width: 100%;
  height: auto;
}

.class_info {
  text-align: center;
}

.class_info a {
  font-size: 23px;
  line-height: 1.65em;
  text-decoration: none;
  font-weight: 400;
}

.class_info h2 {
  font-size: 15px;
  line-height: 1em;

}

.class_info p {
  font-size: 20px;
  line-height: 1.8em;
}

.class_btn {
  border: 2px solid white;
  background-color: black;
  color: white;
  margin-bottom: .5em;
  font-size: 18px;
  font-weight: 600;
}


@media(max-width: 800px){
  .class_cardContainer {
    width: 45%;
    margin-top: 0;

  }
  
  .class_info h4 {
    font-size: 15px;
    line-height: .3em;
  
  }
  
  .class_info p {
    font-size: 17px;
    line-height: .7em;
  }

  .class_btn {
    margin: .5em 0;
    font-size: 15px;
    font-weight: 600;
  }
}