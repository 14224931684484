.staff_cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28%;
  height: 48rem;
  margin: 1em 0;
  white-space: pre-wrap;
  background-color: #C9283D;
}

.staff_cardContainer a {
  font-size: 23px;
  line-height: 1em;
  text-decoration: none;
  font-weight: 400;
  color: black;
  padding-bottom: .3em;
} 

.staff_image {
  width: 100%;
}

.staff_info {
  text-align: center;
  color: white;
  width: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.staff_info h3 {
  font-size: 32px;
  line-height: .2em;
  margin-top: .6em;
}

.staff_info h4 {
  font-size: 24px;
  text-decoration: underline overline black;
  line-height: .3em;
}

.staff_info p {
  font-size: 18px;
  line-height: 1em;
}

@media(max-width: 800px) {
  .staff_cardContainer {
    width: 90%;
    height: auto;
  }
}