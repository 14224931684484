.level_cardContainer {
  width: 60%;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #C9283D;
  text-align: left;
}

.level_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
}

.level_info h4 {
  margin-right: .2rem;
  font-size: 1rem;
}

.level_info p {
  font-size: .9rem;
}

.level_japanese {
  font-size: .8rem;
}

.level_left {
  display: flex;
  align-items: center;
}

.level_left p {
  font-size: 2rem;
  color: black;
}

@media(max-width: 800px){
  .level_cardContainer {
    width: 90%;
  }

  .level_info {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .level_info h4 {
    margin-right: .2rem;
    font-size: 1.3rem;
  }
  
  .level_info p {
    font-size: 1.1rem;
  }
  
  .level_japanese {
    font-size: 1rem;
  }
  
  .level_left p {
    font-size: 3rem;
  }
}