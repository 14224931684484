.ins_schedContainer {
  text-align: center;
  width: 250px;
  color: white;
  border: black solid 2px;
  background-color: black;
}

.ins_schedInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ins_schedInfo h3 {
  font-size: 22px;
  line-height: 1em;

}

.ins_schedInfo p {
  font-size: 20px;
  line-height: 1.8em;

}

.ins_btn {
  border: 2px solid white;
  background-color: black;
  color: white;
  margin-bottom: .5em;
  font-size: 18px;
  font-weight: 600;
}