.sched_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  margin-top: 50px;
}

.sched_top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sched_top h2 {
  margin: 1em;
  font-size: 25px;
}

.sched_dropdown {
  font-size: 20px;
  background-color: rgb(0, 0, 0);
  color: white;
  height: 55px;
}


@media(max-width: 800px){
  .sched_container {
    margin-top: 250px;
  }

  .sched_top {
    justify-content: center;
    width: 100%;
  }
}