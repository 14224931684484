:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size:60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}


.navContainer {
  background-color: black;
  height: 8.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  color: aliceblue;
  top: 0;
}

.navLeft {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px;
  padding-left: 4rem;
}

.navLeft img {
  width: 90px;
  height: 90px;
  margin-right: 15px;
}

.navRight {
  display: flex;
  flex-direction: column-reverse;
  width: 40%;
}

.navInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.navInfo a {
  color: aliceblue;
  text-align: center;
  text-decoration: none;
}

.navInfoRight {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
}

.navLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  text-align: center;
}

.navLinks a, .navLinks p {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
}

.navDropDownCol {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.navDropDownCol a {
  text-decoration: none;
  padding: .8rem 0;
  display: block;
}

.navDropDown {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: center;
  left: -.7rem;
  justify-content: center;
  background-color: #C9283D;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  min-width: 6rem;
  overflow: auto;
  z-index: 1;
}

.navDropDown a {
  text-decoration: none;
  padding: .8rem 0;
  color: aliceblue;
  display: block;
  text-align: center;
}

.navDropDownCol a:hover, .navDropDownCol a:focus, .navDropDownCol p:hover, .navDropDownCol p:focus {
  background-color: black;
  color: aliceblue;
}

.navShow {
  display: flex;
}

.navJapanese {
  font-size: 13px;
}

@media(max-width: 800px){
  .navContainer {
    flex-direction: column;
    padding: 1rem 0;
    height: 12.5rem;
  }

  .navRight {
    width: 100%;
  }

  .navLinks {
    width: 100%;
  }

  .navInfo {
    padding-left: 0;
  }

  .navInfo img {
    width: 75%;
  }

  .navInfoRight {
    flex-direction: column;
    justify-content: space-evenly;
  }
}